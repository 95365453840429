<template>
  <v-container fluid>
    <v-tabs
      v-model="tab"
      background-color="grey lighten-2"
      v-if="role === 'pl' || role === 'admin'"
    >
      <v-tab key="next">Anstehende Wartungen</v-tab>
      <!-- <v-tab key="finalized"
        >Übermittelte Wartungen

        <v-badge
          v-if="finalizedwartungen.length > 0"
          class="ml-1"
          color="error"
          :content="finalizedwartungen.length"
        >
        </v-badge>
      </v-tab> -->
      <v-tab key="donewartungen">Abgeschlossen Wartungen</v-tab>
    </v-tabs>

    <v-tabs
      v-model="tab"
      background-color="grey lighten-2"
      v-if="role === 'worker'"
    >
      <v-tab key="todo">Anstehende Wartungen</v-tab>
      <!--  <v-tab key="assignments">Arbeitsnachweise</v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tab" v-if="role === 'pl' || role === 'admin'">
      <v-tab-item key="next">
        <v-card class="mt-10" flat>
          <v-card-title class="headline">Anstehende Wartungen</v-card-title>
          <v-card-text>
            <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
              <v-text-field
                v-model="search1"
                append-icon="search"
                label="Suche"
              ></v-text-field>
            </v-col>
            <v-data-table
              :headers="headers"
              :items="nextwartungen"
              hide-default-footer
              :search="search1"
              class="row-pointer"
              @click:row="handleClick"
              :items-per-page="-1"
            >
              <template v-slot:item.address="{ item }"
                >{{ item.cstreet }} {{ item.cno }}, {{ item.czip }}
                {{ item.ccity }}</template
              >
              <template v-slot:item.duration="{ item }"
                >{{ item.duration }} Tage</template
              >
              <template v-slot:item.assignmentnumber="{ item }">
                <v-btn
                  outlined
                  color="primary"
                  @click.stop="$router.push('assignment/' + item.assignmentid)"
                  v-if="item.assignmentid"
                  >{{ item.assignmentnumber }}</v-btn
                >
                <span v-else class="red-text bold">Kein Auftrag</span>
              </template>
              <template v-slot:item.nextservice="{ item }">
                <div v-html="getClass(item.nextservice)"></div>
              </template>
              <template v-slot:item.lastservice="{ item }">
                <div>{{ $formatDate(item.lastservice) }}</div>
              </template>
              <template v-slot:item.price="{ item }"
                >{{ formatPrice(item.price) }} EUR</template
              >
              <template v-slot:item.cycle="{ item }"
                >{{ item.cycle }} pro Jahr</template
              >
              <template v-slot:item.username="{ item }">
                <div
                  style="color: red; font-weight: bold"
                  v-if="
                    !item.username ||
                    item.username === '' ||
                    item.status === '3'
                  "
                >
                  Nicht zugewiesen
                </div>
                <div style="color: green" v-else>{{ item.username }}</div>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click.stop="openWorkerDialog(item)"
                      fab
                      small
                      color="primary"
                    >
                      <v-icon>mdi-clipboard-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Auftrag zuweisen</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="
                        $router.push(
                          '/wartungsdetails/view/' + item.wid + '/' + item.id
                        )
                      "
                      fab
                      small
                      color="primary"
                    >
                      <i class="material-icons">playlist_add_check</i>
                    </v-btn>
                  </template>
                  <span>Wartungsdetatils anzeigen</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!--   <v-tab-item key="finalized">
        <v-card class="mt-10" flat>
          <v-card-title class="headline">Übermittelte Wartungen</v-card-title>
          <v-card-text>
            <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
              <v-text-field
                v-model="search2"
                append-icon="search"
                label="Suche"
              ></v-text-field>
            </v-col>
            <v-data-table
              :headers="headers"
              :items="finalizedwartungen"
              hide-default-footer
              :search="search2"
              :items-per-page="-1"
            >
              <template v-slot:item.address="{ item }"
                >{{ item.cstreet }} {{ item.cno }}, {{ item.czip }}
                {{ item.ccity }}</template
              >
              <template v-slot:item.assignmentnumber="{ item }">
                <v-btn
                  outlined
                  color="primary"
                  @click="$router.push('assignment/' + item.assignmentid)"
                  v-if="item.assignmentid"
                  >{{ item.assignmentnumber }}</v-btn
                >
              </template>
              <template v-slot:item.nextservice="{ item }">
                <div v-html="getClass(item.nextservice)"></div>
              </template>
              <template v-slot:item.lastservice="{ item }">
                <div>{{ $formatDate(item.lastservice) }}</div>
              </template>
              <template v-slot:item.duration="{ item }"
                >{{ item.duration }} Tage</template
              >
              <template v-slot:item.price="{ item }"
                >{{ formatPrice(item.price) }} EUR</template
              >
              <template v-slot:item.cycle="{ item }"
                >{{ item.cycle }} pro Jahr</template
              >
              <template v-slot:item.action="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="
                        $router.push(
                          '/wartungsdetails/view/' + item.wid + '/' + item.id
                        )
                      "
                      fab
                      small
                      color="primary"
                    >
                      <i class="material-icons">playlist_add_check</i>
                    </v-btn>
                  </template>
                  <span>Wartungsdetatils anzeigen</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="openWorkerDialog(item)"
                      fab
                      small
                      color="primary"
                    >
                      <i class="material-icons">perm_identity</i>
                    </v-btn>
                  </template>
                  <span>Service-Techniker zuweisen</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item> -->
      <v-tab-item key="donewartungen">
        <v-card class="mt-10" flat>
          <v-card-title class="headline">Abgeschlossene Wartungen</v-card-title>
          <v-card-text>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                v-model="search"
                append-icon="search"
                @keydown.enter="startSearch"
                label="Suche"
              ></v-text-field>
              <v-btn color="primary" @click="startSearch">Suchen</v-btn>
            </v-col>
            <v-data-table
              :headers="headersdone"
              :items="done"
              hide-default-footer
              :items-per-page="-1"
              item-key="wid"
            >
              <template v-slot:item.address="{ item }"
                >{{ item.cstreet }} {{ item.cno }}, {{ item.czip }}
                {{ item.ccity }}</template
              >
              <template v-slot:item.assignmentnumber="{ item }">
                <v-btn
                  outlined
                  color="primary"
                  @click.stop="$router.push('assignment/' + item.assignmentid)"
                  v-if="item.assignmentid"
                  >{{ item.assignmentnumber }}</v-btn
                >
              </template>
              <template v-slot:item.finalized="{ item }">
                <div>{{ $formatDate(item.finalized) }}</div>
              </template>
              <template v-slot:item.duration="{ item }"
                >{{ item.duration }} Tage</template
              >
              <template v-slot:item.price="{ item }"
                >{{ formatPrice(item.price) }} EUR</template
              >
              <template v-slot:item.cycle="{ item }"
                >{{ item.cycle }} pro Jahr</template
              >
              <template v-slot:item.action="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="
                        $router.push(
                          '/wartungsdetails/view/' + item.wid + '/' + item.id
                        )
                      "
                      fab
                      small
                      color="primary"
                    >
                      <i class="material-icons">playlist_add_check</i>
                    </v-btn>
                  </template>
                  <span>Wartungsdetatils anzeigen</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="tab" v-if="role === 'worker'">
      <v-tab-item key="todo">
        <v-card class="mt-10">
          <v-card-title class="headline">Anstehende Wartungen</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="todowartungen"
              :items-per-page="-1"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:item.address="{ item }"
                >{{ item.cstreet }} {{ item.cno }}, {{ item.czip }}
                {{ item.ccity }}</template
              >
              <template v-slot:item.assignmentnumber="{ item }">
                <v-btn
                  outlined
                  color="primary"
                  @click="$router.push('assignment/' + item.assignmentid)"
                  v-if="item.assignmentid"
                  >{{ item.assignmentnumber }}</v-btn
                >
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="
                        $router.push(
                          '/wartungsdetails/execute/' + item.wid + '/' + item.id
                        )
                      "
                      fab
                      small
                      color="primary"
                    >
                      <i class="material-icons">playlist_add_check</i>
                    </v-btn>
                  </template>
                  <span>Wartungsdetatils anzeigen</span>
                </v-tooltip>
              </template>
              <template v-slot:item.nextservice="{ item }">
                <div v-html="getClass(item.nextservice)"></div>
              </template>
              <template v-slot:item.lastservice="{ item }">
                <div>{{ $formatDate(item.lastservice) }}</div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!--  <v-tab-item key="assignments">
        <v-card class="mt-10">
          <v-card-title class="headline">Arbeitsnachweise</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersassignment"
              :items="assignments"
              hide-default-footer
              :items-per-page="-1"
              class="elevation-1"
            >
              <template v-slot:item.status="{ item }">
                <span v-if="item.status == '0'" class="orange-highlight">Erstellt</span>
                <span v-if="item.status == '1'" class="orange-highlight">In Bearbeitung</span>
                <span v-if="item.status == '2'" class="darkred-highlight">Übermittelt</span>
                <span v-if="item.status == '3'" class="green-highlight">Abgeschlossen</span>
              </template>
              <template
                v-slot:item.address="{ item }"
              >{{item.cstreet}} {{item.cno}}, {{item.czip}} {{item.ccity}}</template>
              <template v-slot:item.action="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="$router.push('/arbeitsnachweis/execute/' + item.id)"
                      fab
                      small
                      color="primary"
                    >
                      <i class="material-icons">playlist_add_check</i>
                    </v-btn>
                  </template>
                  <span>Arbeitsnachweis anzeigen</span>
                </v-tooltip>
              </template>
              <template v-slot:item.date="{ item }">
                <div>{{$formatDate(item.date)}}</div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>-->
    </v-tabs-items>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <h3 class="center">
            Wartung zuweisen für Block "{{ selectedItem.name }}"
          </h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-layout wrap>
                <!--  <v-flex xs12 sm12 md12>
                  <v-select
                    clearable
                    multiple
                    v-model="userids"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    label="Service-Techniker"
                  ></v-select>
                </v-flex> -->
                <v-flex xs12 sm12 md12>
                  <v-select
                    item-text="assignmentnumber"
                    :loading="isLoading"
                    :items="assignments"
                    clearable
                    return-object
                    label="Auftrag auswählen"
                    v-model="selectedAssigment"
                  >
                    <!--   <template v-slot:item="data">
                <strong>{{ data.item.assignmentnumber }}</strong> ({{ data.item.cname }}) 
              </template> -->
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.assignmentnumber"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-if="item.customername"
                          v-text="item.customername"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                  <!--      <v-autocomplete
                    v-model="model"
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    item-text="assignmentnumber"
                    clearable
                    label="Auftrag zuweisen"
                    placeholder="Geben sie eine Auftragsnummer oder kundennamen ein"
                    return-object
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.assignmentnumber"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.cname"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete> -->
                </v-flex>
                <!--  <v-flex xs12 sm12 md12>
                  <v-checkbox
                    v-model="createAssignment"
                    label="Auftrag für Arbeitsnachweis erstellen"
                  ></v-checkbox>
                </v-flex>

                <v-flex xs12 sm12 md12 v-if="createAssignment">
                  <v-text-field
                    label="Auftragsnummer*"
                    v-model="newAssignment.assignmentnumber"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="createAssignment">
                  <v-select
                    clearable
                    multiple
                    v-model="newAssignment.plids"
                    :items="leiters"
                    item-text="name"
                    item-value="id"
                    label="Projektleiter"
                    required
                    :rules="[rules.requiredarray]"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="createAssignment">
                  <v-textarea outlined v-model="newAssignment.comment" label="Kommentar"></v-textarea>
                </v-flex> -->
              </v-layout>
            </v-form>
          </v-container>
          <v-btn
            outlined
            color="primary"
            :disabled="selectedAssigment != null"
            @click="createNewAssignment()"
            >Neuen Auftrag erstellen</v-btn
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="selectedAssigment == null"
            elevation="0"
            @click="assignAssignment()"
            >Zuweisen</v-btn
          >
          <v-btn
            color="error"
            text
            @click="
              dialog = false;
              model = null;
              selectedAssigment = null;
            "
            >Abbrechen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirm" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Auftrag bereits vorhanden</v-card-title>
        <v-card-text>
          Es besteht bereits ein Auftrag mit der Auftragsnummer
          <b>{{ newAssignment.assignmentnumber }}</b
          >. Was möchten Sie tun?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="addAssignment(true)"
            >Auftrag überschreiben</v-btn
          >
          <v-btn color="success" text @click="addAssignment(false)"
            >Auftrag erstellen</v-btn
          >
          <v-btn color="error" text @click="confirm = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNewAssignment" persistent max-width="700px">
      <v-card>
        <v-card-title> Neuen Auftrag erstellen </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-form ref="form">
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <div>
                    <b>Kundenname</b>
                  </div>
                  <div>{{ newAssignment.customername }}</div>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    v-model="newAssignment.standortid"
                    item-text="name"
                    item-value="id"
                    :items="standorte"
                    :disabled="!standorte.length"
                    label="Standort*"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="newAssignment.date"
                        label="Datum*"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        required
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newAssignment.date"
                      no-title
                      first-day-of-week="1"
                      scrollable
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Auftragsnummer*"
                    v-model="newAssignment.assignmentnumber"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    clearable
                    v-model="newAssignment.type"
                    :items="['Service', 'Wartung', 'Montage']"
                    label="Auftragstyp"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    clearable
                    multiple
                    v-model="newAssignment.userids"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    label="Mitarbeiter"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    clearable
                    multiple
                    v-model="newAssignment.plids"
                    :items="leiters"
                    item-text="name"
                    item-value="id"
                    label="Projektleiter"
                    required
                    :rules="[rules.requiredarray]"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    outlined
                    v-model="newAssignment.comment"
                    label="Kommentar"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-form>
            <v-alert type="error" :value="numberExsists"
              >Die von ihnen gewähle Auftragsnummer exsistiert bereits. Bitte
              wählen Sie eine andere Auftragsnummer</v-alert
            >
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="addAssignment(1)"
            >Auftrag Erstellen</v-btn
          >
          <v-btn
            text
            color="error"
            @click="
              showNewAssignment = false;
              clearAssignment();
            "
            >Abbrechen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      selectedItem: "",
      userids: "",
      menu: false,
      createAssignment: false,
      newAssignment: {},
      numberExsists: false,
      users: [],
      leiters: [],
      selectedAssigment: null,
      nextwartungen: [],
      done: [],
      finalizedwartungen: [],
      todowartungen: [],
      assignments: [],
      search: "",
      search1: "",
      search2: "",
      standorte: [],
      showNewAssignment: false,
      confirm: false,
      tab: "",
      items: [],
      isLoading: false,
      model: null,
      newAssignment: {
        date: "",
        assignmentnumber: "",
        customerid: "",
        standortid: "",
        plids: "",
        plnames: "",
        info: "",
        userids: "",
        userames: "",
        comment: "",
      },
      headers: [
        {
          text: "Kundenname",
          align: "left",
          value: "cname",
        },
        {
          text: "Kundenkürzel",
          align: "center",
          value: "code",
        },
        {
          text: "Wartungsblock Name",
          align: "left",
          value: "name",
        },
        {
          text: "Objektadresse",
          sortable: false,
          value: "address",
        },
        {
          text: "Letzte Wartung",
          value: "lastservice",
        },
        {
          text: "Nächste Wartung",
          value: "nextservice",
        },
        /*   {
          text: "Dauer",
          value: "duration",
        }, */
        {
          text: "Wartungszyklus",
          value: "cycle",
        },
        {
          text: "Preis",
          value: "price",
        },
        /*   {
          text: "Service-Techniker",
          value: "username",
        }, */
        {
          text: "Auftrag",
          align: "left",
          value: "assignmentnumber",
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action",
        },
      ],
      headersdone: [
        {
          text: "Kundenname",
          align: "left",
          value: "cname",
        },
        {
          text: "Kundenkürzel",
          align: "center",
          value: "code",
        },
        {
          text: "Wartungsblock Name",
          align: "left",
          value: "name",
        },
        {
          text: "Objektadresse",
          sortable: false,
          value: "address",
        },
        {
          text: "Durchgeführt am",
          value: "finalized",
        },
        {
          text: "Wartungszyklus",
          value: "cycle",
        },
        {
          text: "Auftrag",
          align: "left",
          value: "assignmentnumber",
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action",
        },
      ],
      headersassignment: [
        {
          text: "Datum",
          align: "left",
          sortable: true,
          value: "date",
        },
        {
          text: "Auftragsnummer",
          align: "left",
          sortable: true,
          value: "assignmentnumber",
        },
        {
          text: "Kundennamen",
          align: "left",
          sortable: true,
          value: "customername",
        },
        {
          text: "Standortbezeichnung",
          align: "left",
          sortable: false,
          value: "standortname",
        },
        {
          text: "Mitarbeiternamen",
          align: "left",
          sortable: true,
          value: "usernames",
        },
        {
          text: "Projektleiter",
          align: "left",
          sortable: true,
          value: "plnames",
        },
        {
          text: "Kommentar",
          align: "left",
          sortable: false,
          value: "comment",
        },
        {
          text: "Erstellt",
          align: "left",
          sortable: false,
          value: "creator",
        },
        {
          text: "Status",
          align: "left",
          value: "status",
        },
        {
          text: "Aktion",
          align: "center",
          value: "action",
        },
      ],
      rules: {
        required: (value) => !!value || "Erforderlich.",
        requiredarray: (value) => !!value.length || "Erforderlich.",
      },
    };
  },
  computed: {
    role() {
      return this.$store.getters.user.role;
    },
    online() {
      return this.$store.getters.online;
    },
  },
  watch: {
    search1(val) {
      localStorage.setItem("wksgn_wartungs_filter", val);
    },
    /*    search(val) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("term", val);
      this.$http({
        method: "post",
        url: "getAssignmentsSearch.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.items = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    }, */
  },
  mounted: function () {
    if (this.role === "admin" || this.role === "pl") {
      this.tab = "next";
    } else {
      this.tab = "todo";
    }
    const search = localStorage.getItem("wksgn_wartungs_filter");
    if (search) {
      this.search1 = search;
    }
    this.getWartungen();
  },
  methods: {
    handleClick(item) {
      this.$router.push('/wartungsdetails/view/' + item.wid + '/' + item.id);
    },
    startSearch() {
      const val = this.search;
      if (!val) {
        this.items = [];
        return;
      }
      this.isLoading = true;
      const self = this;
      let formData = new FormData();
      formData.append("term", val);
      this.$http({
        method: "post",
        url: "findWartungen.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            self.done = response.data.data;
            if (!response.data.data.length) {
              const msg =
                "Ihre Suche ergab keinen Treffer. Bitte versuchen Sie es mit einem anderen Suchbegriff.";
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
    createNewAssignment() {
      this.showNewAssignment = true;
      this.standorte = [];
      const userid = this.$store.getters.user.id;
      this.newAssignment = {
        userids: JSON.parse(JSON.stringify(this.userids)),
        plids: [userid],
        type: "Wartung",
        customerid: this.selectedItem.customerid,
        customername: this.selectedItem.customername,
        date: this.$formatDateISO(new Date()),
        comment: "",
      };
      this.getStandorte(this.selectedItem.customerid);
    },
    getStandorte(id) {
      let formData = new FormData();
      formData.append("id", id);
      this.$http({
        method: "post",
        url: "getStandorte.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.standorte = response.data.data;
          }
        })
        .catch(function (error) {
          error;
        });
    },
    getWartungen() {
      if (this.online) {
        var self = this;
        this.$http({
          method: "post",
          url: "getWartungen.php",
        })
          .then((response) => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (response.data && response.data.success) {
              if (response.data.todos) {
                self.todowartungen = response.data.todos;
              }
              /*   if (response.data.assignments) {
                self.assignments = response.data.assignments;
              } */
              if (response.data.next) {
                self.nextwartungen = response.data.next;
              }
              if (response.data.finalized) {
                self.finalizedwartungen = response.data.finalized;
              }
              if (response.data.done) {
                self.done = response.data.done;
              }
              if (response.data.users) {
                self.users = response.data.users;
              }
              if (response.data.leiters) {
                self.leiters = response.data.leiters;
              }
            }
          })
          .catch(function (error) {});
      } else {
        const data = this.$store.getters.offlinedata;
        if (data.todowartungen) {
          this.todowartungen = data.todowartungen;
        }
        /*  if (data.assignments) {
          this.assignments = data.assignments;
        } */
        if (data.nextwartungen) {
          this.nextwartungen = data.nextwartungen;
        }
        if (data.finalizedwartungen) {
          this.finalizedwartungen = data.finalizedwartungen;
        }
        if (data.users) {
          this.users = data.users;
        }
        if (data.leiters) {
          this.leiters = data.leiters;
        }
      }
    },
    getClass(value) {
      if (!value || value.indexOf("0000") > -1) {
        return '<span class="text-center darkred-highlight">-</span>';
      }
      const date = new Date(value);
      const now = new Date();
      const diff = date - now;
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      if (diffDays < 0) {
        return (
          '<span class="darkred-highlight">' +
          this.$moment(value).format("DD.MM.YYYY") +
          "</span>"
        );
      }
      if (diffDays < 60) {
        return (
          '<span class="orange-highlight">' +
          this.$moment(value).format("DD.MM.YYYY") +
          "</span>"
        );
      }
      return (
        '<span class="green-highlight">' +
        this.$moment(value).format("DD.MM.YYYY") +
        "</span>"
      );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    /* changeWorker(id) {
      const user = this.users.find(u => {
        return u.id === id;
      });
      if (user) {
        this.userid = id;
        this.selectedItem.userid = id;
        this.selectedItem.username = user.name;
      } else {
        this.userid = "";
        this.selectedItem.userid = undefined;
        this.selectedItem.username = undefined;
      }
    }, */
    openWorkerDialog(item) {
      this.selectedItem = {
        customerid: item.customerid,
        customername: item.cname,
        userid: item.userid,
        wid: item.wid,
        name:item.name,
        status: item.status,
        username: item.username,
        id: item.id,
      };
      const ids =
        item.userid && item.userid !== "" ? JSON.parse(item.userid) : [];
      let userids = [];
      ids.forEach((id) => {
        userids.push(id + "");
      });
      if (item.assignmentid) {
        this.selectedAssigment = {
          id: item.assignmentid,
          assignmentnumber: item.assignmentnumber,
        };
      }
      this.userids = userids;
      if (this.assignments || !this.assignments.length) {
        this.getOpenAssignments();
      }
      this.dialog = true;
    },
    getOpenAssignments() {
      if (this.assignments && this.assignments.length) {
        return;
      }
      this.isLoading = true;
      this.$http({
        method: "post",
        url: "getOpenAssignments.php",
      })
        .then((response) => {
          this.isLoading = false;
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.assignments = response.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },
    checkAssignment() {
      let newAssignment = JSON.parse(JSON.stringify(this.newAssignment));
      let formData = new FormData();
      formData.append("id", newAssignment.assignmentnumber);
      this.$http({
        method: "post",
        url: "getAssignmentByNumber.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            if (response.data.notFound) {
              this.addAssignment(false);
            }
            if (response.data.info) {
              this.newAssignment.id = response.data.info.id;
              this.confirm = true;
            }
          } else {
          }
        })
        .catch(function (error) {
          error;
        });
    },
    addAssignment() {
      this.numberExsists = false;
      if (this.$refs.form.validate()) {
        let newAssignment = JSON.parse(JSON.stringify(this.newAssignment));
        newAssignment["creator"] = this.$store.getters.user.name;
        const userids = newAssignment["userids"];
        let usernames = [];
        let ids = [];
        if (userids) {
          userids.forEach((id) => {
            const user = this.users.find((u) => {
              return u.id === id;
            });
            if (user && user.name && user.id) {
              usernames.push(user.name);
              ids.push(parseInt(user.id));
            }
          });
        }
        newAssignment["usernames"] = usernames.join(", ");
        newAssignment["userids"] = ids;

        const plids = newAssignment["plids"];
        let plnames = [];
        let pids = [];
        if (plids) {
          plids.forEach((id) => {
            const pl = this.leiters.find((u) => {
              return u.id === id;
            });
            if (pl && pl.name && pl.id) {
              plnames.push(pl.name);
              pids.push(parseInt(pl.id));
            }
          });
        }
        newAssignment["plnames"] = plnames.join(", ");
        newAssignment["plids"] = pids;
        const date = Math.round(new Date(newAssignment.date).getTime() / 1000);
        newAssignment["time"] = date;
        let formData = new FormData();
        newAssignment["status"] = 1;
        formData.append("assignment", JSON.stringify(newAssignment));
        this.$http({
          method: "post",
          url: "saveAssignment.php",
          data: formData,
        })
          .then((response) => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (
              response.data &&
              response.data.success === false &&
              response.data.authorization
            ) {
              const msg =
                "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
              const color = "error";
              this.$store.dispatch("snackbar", { msg, color });
              this.confirm = false;
              return;
            }
            if (response.data && response.data.success) {
              let msg = "";
              msg = "Der Auftrag wurde erfolgreich erstellt";
              this.showNewAssignment = false;
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
              this.selectedAssigment = {
                id: response.data.id,
                assignmentnumber: newAssignment["assignmentnumber"],
              };
              this.assignments.push({
                id: response.data.id,
                assignmentnumber: newAssignment["assignmentnumber"],
              });
              /* this.model = {
                id: response.data.id,
                assignmentnumber: newAssignment["assignmentnumber"],
              };
              this.items.push({
                id: response.data.id,
                assignmentnumber: newAssignment["assignmentnumber"],
              }); */
            } else {
              if (response.data.numberExsists) {
                this.numberExsists = true;
              } else {
                const msg =
                  "Der Auftrag konnten nicht erstellt werden. Bitte versuchen Sie es erneut.";
                const color = "error";
                this.$store.dispatch("snackbar", { msg, color });
              }
            }
          })
          .catch((error) => {
            const response = error.response.data;
            if (
              response.status === 500 &&
              response.message === "NoLoggedInUser"
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
              return;
            }
            this.$router.push("/");
          });
      }
    },
    /*   addAssignment(edit) {
      this.confirm = false;
      let newAssignment = JSON.parse(JSON.stringify(this.newAssignment));
      newAssignment["creator"] = this.$store.getters.user.name;
      const item = this.selectedItem;
      const userids = this.userids;
      let usernames = [];
      let ids = [];
      if (userids) {
        userids.forEach((id) => {
          const user = this.users.find((u) => {
            return u.id === id;
          });
          if (user && user.name && user.id) {
            usernames.push(user.name);
            ids.push(parseInt(user.id));
          }
        });
      }
      newAssignment["usernames"] = usernames.join(", ");
      newAssignment["userids"] = ids;
      newAssignment["customerid"] = item.customerid;
      const plids = newAssignment["plids"];
      let plnames = [];
      let pids = [];
      if (plids) {
        plids.forEach((id) => {
          const pl = this.leiters.find((u) => {
            return u.id === id;
          });
          if (pl && pl.name && pl.id) {
            plnames.push(pl.name);
            pids.push(parseInt(pl.id));
          }
        });
      }
      newAssignment["plnames"] = plnames.join(", ");
      newAssignment["plids"] = pids;
      newAssignment["standortid"] = "";
      const date = Math.round(new Date().getTime() / 1000);
      newAssignment["time"] = date;
      let formData = new FormData();
      newAssignment["status"] = 1;
      formData.append("assignment", JSON.stringify(newAssignment));
      formData.append("edit", edit);
      this.$http({
        method: "post",
        url: "saveAssignment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            const msg = "Der Auftrag wurde erfolgreich erstellt";
            const color = "success";
            this.clearAssignment();
            this.createAssignment = false;
            this.dialog = false;
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Der Auftrag konnten nicht erstellt werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          error;
        });
    }, */

    assignAssignment() {
      const assignment = this.selectedAssigment;
      const item = this.selectedItem;
      const wartungsid =
        item.wid && item.status && item.status !== "3" ? item.wid : "";
      let formData = new FormData();
      formData.append("blockid", item.id);
      formData.append("wartungsid", wartungsid);
      formData.append("assignmentid", assignment.id);
      this.$http({
        method: "post",
        url: "updateWartungsAssignment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response && response.data && response.data.success) {
            let wartungen = this.nextwartungen;
            wartungen.forEach((w) => {
              if (w.id === item.id) {
                w.assignmentnumber = assignment.assignmentnumber;
                w.assignmentid = assignment.id;
              }
            });

            let msg = "";
            if (assignment.assignmentnumber) {
              msg =
                "Die Wartung wurde dem Auftrag " +
                assignment.assignmentnumber +
                " zugewiesen.";
            } else {
              msg = "Der Auftrag wurde entfernt.";
            }
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.dialog = false;
            this.model = null;
            this.selectedAssigment = null;
          } else {
            const msg =
              "Die Wartung konnte nicht dem Auftrag " +
              assignment.assignmentnumber +
              " zugewiesen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          error;
        });
    },
    assignWorker() {
      if (this.$refs.form.validate()) {
        const userids = this.userids;
        let ids = [];
        let usernames = [];
        userids.forEach((id) => {
          const user = this.users.find((u) => {
            return u.id === id;
          });
          if (user && user.name && user.id) {
            usernames.push(user.name);
            ids.push(parseInt(user.id));
          }
        });
        let item = this.selectedItem;
        ids = ids.length ? JSON.stringify(ids) : "[]";
        usernames = usernames.join(", ");
        item.username = usernames;
        item.userid = ids;
        const userblockid =
          item.wid && item.status && item.status !== "3" ? item.wid : "";

        let formData = new FormData();
        formData.append("blockid", item.id);
        formData.append("userblockid", userblockid);
        formData.append("userids", ids);
        formData.append("usernames", usernames || "");
        if (this.model && this.model.id) {
          formData.append("assignmentid", this.model.id);
        }
        this.$http({
          method: "post",
          url: "updateUserBlock.php",
          data: formData,
          config: {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Accept: "application/json",
            },
          },
        })
          .then((response) => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (response && response.data && response.data.success) {
              let wartungen = this.nextwartungen;
              wartungen.forEach((w) => {
                if (w.id === item.id) {
                  w.userid = item.userid;
                  w.username = item.username;
                }
              });

              let msg = "";
              if (this.selectedItem && this.selectedItem.username) {
                msg =
                  "Die Wartung wurde dem Service-Techniker " +
                  this.selectedItem.username +
                  " zugewiesen.";
              } else {
                msg = "Die Zuweisung des Service-Technikers wurde entfernt.";
              }
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
              /*   if (this.createAssignment) {
                this.checkAssignment();
              } else { */
              this.dialog = false;
              this.model = null;
              // }
            } else {
              const msg =
                "Die Wartung konnte nicht dem Service-Techniker " +
                this.selectedItem.username +
                " zugewiesen werden. Bitte versuchen Sie es erneut.";
              const color = "error";
              this.$store.dispatch("snackbar", { msg, color });
            }
          })
          .catch(function (error) {
            error;
          });
      }
    },
    clearAssignment() {
      this.newAssignment = {
        date: "",
        assignmentnumber: "",
        customerid: "",
        standortid: "",
        plids: "",
        plnames: "",
        info: "",
        userids: "",
        userames: "",
        comment: "",
      };
    },
  },
};
</script>
